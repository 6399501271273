import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";

import { saveSourceFinancing as onSaveSourceFinancing } from "../../store/Config/actions"

const FormSourceFinancing = ({sourceFinancingList}) => {

    const dispatch = useDispatch()


    const sFinancing = [
        { id: 1, name: "Recursos Fiscales", bank:"",account_number:"" },
        { id: 2, name: "Ramo 28", bank:"",account_number:"" },
        { id: 3, name: "Ramo 33 Fondo IV", bank:"",account_number:"" },
        { id: 4, name: "Ramo 33 Fondo III", bank:"",account_number:"" },
        { id: 5, name: "Convenios Federelaes", bank:"",account_number:"" },
        { id: 6, name: "Ramo 28 2023", bank:"",account_number:"" },
        { id: 7, name: "Fondo III 2023", bank:"",account_number:"" },
        { id: 7, name: "Fondo IV 2023", bank:"",account_number:"" },
      ]

    const [sourceFinancing, setSourceFinancing] = useState([])


    const   handleSourceFinSave = () => {
      dispatch(onSaveSourceFinancing({sourceFinancingList:sourceFinancing}))
    }

    const handleValueFinancing = (e, key) => {
      const sFList = [...sourceFinancing]
      const source = sFList[key];
      source[e.target.name] = e.target.value;
      sFList.splice(key,1,source)
      console.info(source)
      setSourceFinancing(sFList)
    }

    useEffect(()=>{
      setSourceFinancing(sourceFinancingList.length>0?sourceFinancingList:sFinancing)
    },[sourceFinancingList])

    return (
        <>
        <Form onSubmit={(e)=>{
            e.preventDefault();
            handleSourceFinSave()
           
        }}>
            <Card>
                <CardBody>
                  <CardTitle>Catálogo de Fuentes de Financiamiento </CardTitle>
                  <p className="card-title-desc">
                    Información de bancos y cuentas
                  </p>
                    {
                      
                      sourceFinancing.map((item, key) => {return (
                      <Row key={key}>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="f1">Fuente</Label>
                          <Input
                            id="f1"
                            name="f1" value={item.name} disabled={true}
                            type="text"
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-3">
                          <Label  htmlFor="b1">
                            Banco
                          </Label>
                          <Input
                            className="form-control"
                            id={"b1"+key} value={item.bank}
                            name="bank" onChange={(e)=>handleValueFinancing(e, key)}
                            placeholder=""
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-3">
                          <Label htmlFor="c1">
                            Número de Cuenta
                          </Label>
                          <Input
                            className="form-control"
                            id="c1"  value={item.account_number}
                            name="account_number" onChange={(e)=>handleValueFinancing(e, key)}
                            placeholder=""
                          />
                        </div>
                      </Col>
                    </Row>
                    )})
                    }
                    
                    <div className="text-end" >
                      <Button type="submit" className="btn btn-primary waves-effect waves-light" color="primary">
                        Guardar Cambios
                      </Button>
                    </div>
                </CardBody>
              </Card>
        </Form>
        </>
    )
}

export default FormSourceFinancing